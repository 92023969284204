<template>
  <div>
    <b-card-actions
        ref="refreshCard"
        action-refresh
        disable-loading-on-refresh
        @refresh="getItem"
        :loaded.sync="loaded"
        :title="(details ? details.event.name + cardTitle : cardTitle)"
    ></b-card-actions>

    <div v-if="details">
      <EventGeneral :event="details.event" :expert="details.expert"/>
      <EventInformation :event="details.event"/>
      <EventGallery :event="details.event"/>
    </div>
  </div>
</template>
<script>

//
import EventGeneral from '@/views/components/single-booking-parts/EventGeneral'
import EventInformation from '@/views/components/single-booking-parts/EventInformation'
import EventGallery from '@/views/components/single-booking-parts/EventGallery'
import BCardActions from '@core/components/b-card-actions/BCardActions'

export default {
  components: {
    BCardActions,
    EventGallery,
    EventInformation,
    EventGeneral,
  },

  data() {
    return {
      details: null,
      loaded: true,
      cardTitle: ""
    }
  },

  methods: {
    getItem() {
      this.$http.get(`/clients/${this.$route.params.clientid}/users/${this.$route.params.userid}/bookings/${this.$route.params.bookingid}`)
          .then(response => {
            this.details = response.data
          })
    },
  },
  beforeMount() {
    this.addBreadcrumbDataFromRouteParams(this.$route.params)
  },
  mounted() {
    this.getItem()
    this.$http.get(`/clients/${this.$route.params.clientid}/users/${this.$route.params.userid}`)
        .then(response => {
          this.cardTitle = " (" + response.data.client + ": " + response.data.firstname + " " + response.data.name + ")"
        })

    this.$http.get(`/clients/${this.$route.params.clientid}/users/${this.$route.params.userid}`)
        .then(response => {
          let breadcrumbsData = [
            {
              key: 'Clients Edit',
              value: response.data.client
            },
            {
              key: 'Edit User',
              value: response.data.firstname + ' ' + response.data.name
            },
          ]

          this.replaceBreadcrumbsPlaceholders(breadcrumbsData)
        })
        .catch(error => {
        })
  }
}
</script>


<style lang="scss">
.card_info_item {
  margin-bottom: 30px;

  &.empty_card_image {
    margin-left: 60px;
  }

  &.margin_without_image {
    margin-left: 60px;
  }

  &.card-with-image {
    border-left: none;

    .image_side {
      padding-right: 15px;
      width: 65px;

      display: flex;
      align-items: center;
      justify-content: end;

      &.with_icon {
        display: flex;
        align-items: center;
        justify-content: end;
      }

      .icon_box {
        background: rgb(233, 241, 242);
        border-radius: 5px;
        justify-content: center;
        display: flex;
        padding: 15px;

      }
    }
  }

  .info_item_title {
    font-size: 18px;
    padding-bottom: 5px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .card_content {
    border-left: 1px solid #d8d6de;
    padding: 10px 15px;
  }
}

.book_event {
  background-color: rgb(1, 101, 101) !important;
  border-color: rgb(1, 101, 101) !important;
}

@media screen and (max-width: 960px) {
  .card_info_item.card-with-image .image_side,
  .card_info_item.card-with-image .image_side .icon_box {
    display: none;
  }
  .card-with-image {
    flex-direction: column;

  }
  .image_side.with_icon,
  .card_info_item.empty_card_image,
  .card_info_item.card-with-image, {
    margin-left: 0px;
    margin-bottom: 20px;

    .image_side {
      justify-content: start;

      &.with_icon {

        justify-content: start;
      }
    }


  }
  .card-with-image {
    padding-left: 0;
  }
}

</style>
